<template>
  <div>
    <b-row v-if="consultantGoal.month > 2 && consultantGoal.year >= 2025">
      <b-col>
        <h5>Mevcut Kayıt</h5>
        <form-consultant-pending-list-new :data-item="consultantGoal" />
      </b-col>
      <b-col>
        <h5>Güncellenen Hali</h5>
        <form-consultant-pending-list-new :data-item="dataItem" />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <h5>Mevcut Kayıt</h5>
        <form-consultant-pending-list :data-item="consultantGoal" />
      </b-col>
      <b-col>
        <h5>Güncellenen Hali</h5>
        <form-consultant-pending-list :data-item="dataItem" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormConsultantPendingList from '@/views/Sales/Consultant_goals/Pending/List.vue'
import FormConsultantPendingListNew from '@/views/Sales/Consultant_goals/Pending/ListNew.vue'

export default {
  name: 'FormConsultantPendingView',
  components: {
    FormConsultantPendingList,
    FormConsultantPendingListNew,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['consultantGoalPendings/getConsultantGoalPending']
    },
    consultantGoal() {
      return this.$store.getters['consultantGoals/getConsultantGoal']
    },
  },
}
</script>
